export const arabicDataAr = {
  home: "الرئيسية",
  about_us: "معلومات عنا",
  services: "خدماتنا",
  contact_us: "اتصل بنا",
  inquiry: "سؤال",
  news: "أخبار",
  mobile: "هاتف",
  email: "بريد",
  vision: "رؤيتنا",
  mission: "استراتيجيتنا",
  contact_text_3: "الاسم الأول",
  contact_text_4: "اسم العائلة",
  contact_text_5: "بريد إلكتروني",
  contact_text_6: "رقم التليفون",
  contact_text_7: "حدد الموضوع؟",
  contact_text_9: "الرسالة",
  contact_text_10: "اكتب رسالتك..",
  contact_text_11: "أرسل رسالة",
  contact_text_20: "موضوع",
  contact_text_21: "أرسل واتساب",
  contact_text_22: "أرسل بريد",
  send_message: "تم ارسال رسالتك بنجاح",
  error_send_message: "حدث خطأ عند ارسال الرسالة",
  button_about_page_4: "اكتشف فريقنا",
  contact_text_12: "صندوق البريد",
  contact_text_14: "هاتف ارضي",
  contact_text_16: "عنوان",
  inquiry_1: "استفسار عام",
  inquiry_2: "تأمين الخيل",
  inquiry_3: "صيانة الإسطبلات",
  inquiry_4: "التخليص الجمركي",
  site_under_maintenance: "الموقع تحت الصيانه",
};

export default arabicDataAr;
