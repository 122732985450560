import React, { useCallback, useEffect, useState } from "react";
import HeaderPage from "../../components/headerPage/HeaderPage";
import { useTranslation } from "react-i18next";
import img_why_us from "../../components/img/img_why_us.webp";
import logo_about from "../../components/img/logo_about.webp";
import "./about.css";
import Button from "../../components/button/Button";
import axios from "axios";

const About = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [dataheaderAbout, setDataheaderAbout] = useState([]);
  const [dataInfoAbout, setDataInfoAbout] = useState([]);
  const [dataWhyUsInfo, setDataWhyUsInfo] = useState([]);
  const [dataWhyUsPost, setDataWhyUsPost] = useState([]);
  const [dataGalleryInfo, setDataGalleryInfo] = useState([]);
  const [dataGalleryPost, setDataGalleryPost] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataheaderAbout = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/header_about.php`,
        formDataPages
      );

      setDataheaderAbout(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchDataInfoAbout = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/about_info.php`,
        formDataPages
      );

      setDataInfoAbout(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchWhyUsInfo = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/why_us_info.php`,
        formDataPages
      );

      setDataWhyUsInfo(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchWhyUsPost = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/why_us_post.php`,
        formDataPages
      );

      setDataWhyUsPost(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchGalleryInfo = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/gallery_info.php`,
        formDataPages
      );

      setDataGalleryInfo(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchGalleryPost = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/gallery_post.php`,
        formDataPages
      );

      setDataGalleryPost(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchDataheaderAbout();
    fetchDataInfoAbout();
    fetchWhyUsInfo();
    fetchWhyUsPost();
    fetchGalleryInfo();
    fetchGalleryPost();
  }, [
    fetchDataheaderAbout,
    fetchDataInfoAbout,
    fetchWhyUsInfo,
    fetchWhyUsPost,
    fetchGalleryInfo,
    fetchGalleryPost,
  ]);

  const text_1 = t("about_us");
  const text_2 =
    language == "en" ? dataheaderAbout.text_1_en : dataheaderAbout.text_1_ar;

  const truncateText = (text) => {
    if (text.length <= 150) {
      return <span>{text}</span>;
    } else {
      const truncatedText = text.substring(0, 100) + "...";
      return <>{truncatedText}</>;
    }
  };

  // console.log(dataheaderAbout);
  // console.log(dataInfoAbout);
  // console.log(dataWhyUsInfo);
  // console.log(dataWhyUsPost);
  // console.log(dataGalleryInfo);
  // console.log(dataGalleryPost);

  return (
    <>
      <HeaderPage
        img={`${LinkLikeBachend}${dataheaderAbout.img}`}
        text_1={text_1}
        text_2={text_2}
      />

      <div className="main">
        <div>
          <h1>
            {language == "en"
              ? dataInfoAbout.text_1_en
              : dataInfoAbout.text_1_ar}
          </h1>
          <p>
            {language == "en"
              ? dataInfoAbout.text_2_en
              : dataInfoAbout.text_2_ar}
          </p>
        </div>
        <p className="text_width">
          {language == "en" ? dataInfoAbout.text_3_en : dataInfoAbout.text_3_ar}
        </p>
      </div>

      <div className="img_about_home_3">
        <img src={`${LinkLikeBachend}${dataInfoAbout.img}`} alt="home_3" />
      </div>

      <div className="main">
        <p className="text_width">
          {language == "en" ? dataInfoAbout.text_4_en : dataInfoAbout.text_4_ar}
        </p>
      </div>

      <div className="logo_about">
        <img src={logo_about} alt="logo_about" />
      </div>

      <header
        className="why_us about"
        style={{ background: `url("${img_why_us}")` }}
      >
        <div className="text_header color_why_us"></div>
        <div className="text_header color_why_us_2">
          <h1>
            {language == "en"
              ? dataWhyUsInfo.text_1_en
              : dataWhyUsInfo.text_1_ar}
          </h1>
          <p className="p_1">
            {language == "en"
              ? dataWhyUsInfo.text_2_en
              : dataWhyUsInfo.text_2_ar}
          </p>

          <div className="why_us_info">
            {dataWhyUsPost.map((item, i) => (
              <div className="why_us_info_item" key={i}>
                <div>
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item.text_1_en}
                  />
                </div>
                <p>
                  {language == "en"
                    ? truncateText(item.text_1_en)
                    : truncateText(item.text_1_ar)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </header>

      <div className="main">
        <div>
          <h1>
            {language == "en"
              ? dataGalleryInfo.text_1_en
              : dataGalleryInfo.text_1_ar}
          </h1>
        </div>
        <p className="text_width">
          {language == "en"
            ? dataGalleryInfo.text_2_en
            : dataGalleryInfo.text_2_ar}
        </p>

        <div className="gallery">
          <div className="div_gallery">
            {dataGalleryPost.map((item, index) => (
              <div key={index} className="div_gallery_index">
                <img
                  src={`${LinkLikeBachend}${item.img}`}
                  alt={`gallery_${index}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
