const UpdateTextInput = ({ value, name }) => {
  return (
    <span>
      <label
        htmlFor={name}
        style={{ display: "block", marginInlineStart: "unset" }}
      >
        {name?.replaceAll("_", " ")}:
      </label>
      <input
        type="text"
        name={name}
        defaultValue={value}
        id={name}
        required
        placeholder="new value"
      />
    </span>
  );
};
export default UpdateTextInput;
