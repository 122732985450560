import React from "react";
import "./button.css";

const Button = ({ text, data_send }) => {
  return (
    <button className="discover_team" data-send={data_send}>
      {text}
    </button>
  );
};

export default Button;
