import React, { useCallback, useEffect, useState } from "react";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import axios from "axios";

export const Slider = () => {
  const [dataSlider, setDataSlider] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataSlider = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/img_slider.php`,
        formDataPages
      );

      setDataSlider(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  useEffect(() => {
    fetchDataSlider();
  }, [fetchDataSlider]);

  // console.log(dataSlider);

  return (
    <>
      <Swiper
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
      >
        {dataSlider.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              src={`${LinkLikeBachend}${item.img}`}
              alt={`${item.img}_${i}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
