import React, { createContext, useState, useContext } from "react";

export const AppContext = createContext("ltr");

export const AppProvider = ({ children }) => {
  const [direction, setDirection] = useState("ltr");

  return (
    <AppContext.Provider value={{ direction, setDirection }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
