import axios from "axios";
import { Form, useLoaderData } from "react-router-dom";
import UpdateTextInput from "../updateTextInput";
import UpdateImgInput from "../updateImgInput";
import { toast } from "react-toastify";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const loader = async ({ request, params }) => {
  const formDataPages = new FormData();
  formDataPages.append("validation", validation);

  try {
    const response = await axios.post(
      `${LinkLikeBachend}read/${params.page.replaceAll("-", "_")}.php`,
      formDataPages
    );

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  formData.append("validation", validation);

  const updates = Object.fromEntries(formData);
  console.log("updates");
  // console.log(updates);

  console.log(params.page);

  Object.entries(updates).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const response = await axios.post(
      `${LinkLikeBachend}update/${params.page}.php`,
      formData
    );

    if (response.data.request == "successfully") {
      toast.success("Updated successfully");
    } else {
      toast.error("An error occurred when updating");
      console.log(response.data);
    }
  } catch (error) {
    console.error("Error:", error);
  }

  return updates;
};

const UpdateStatics = () => {
  const data = useLoaderData();
  let textInputs, imgInputs;

  textInputs = Object.keys(data).filter(
    (d) =>
      !d.includes("image") &&
      !d.includes("img") &&
      !d.includes("logo") &&
      !d.includes("id")
  );
  imgInputs = Object.keys(data).filter(
    (d) => d.includes("image") || d.includes("img") || d.includes("logo")
  );

  return (
    <Form method="post" encType="multipart/form-data">
      <input type="hidden" name="id" value={data.id} readOnly />
      {imgInputs?.map((key) => (
        <UpdateImgInput key={key} value={data[key]} name={key} />
      ))}

      {textInputs?.map((key) => (
        <UpdateTextInput key={key} value={data[key]} name={key} />
      ))}

      <button type="submit">Update</button>
    </Form>
  );
};
export default UpdateStatics;
