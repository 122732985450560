import styles from "../admin/admin.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const ChanePassword = () => {
  const navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    const formDataPages = new FormData(e.target);
    formDataPages.append("validation", validation);

    try {
      const res = await axios.post(
        `${LinkLikeBachend}update/admin_change_password.php`,
        formDataPages
      );

      console.log(res.data);

      toast.success("Password Changed Successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <h2>Login</h2>
          <form onSubmit={onSubmit}>
            <input
              type="password"
              id="password"
              placeholder="Password"
              required
              name="password_admin"
            />

            <button type="submit">Change Password</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChanePassword;
