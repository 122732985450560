import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import background_img from "../../components/img/background_img.webp";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const UpdateImgInput = ({ value, name, setDisabled }) => {
  const [image, setImage] = useState({ file: "", url: "" });
  const [isEdit, setIsEdit] = useState(false);

  const handleImageChange = (e) => {
    setIsEdit(false);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setImage({
          file: base64data,
          url: URL.createObjectURL(file),
        });
      };
      reader.readAsDataURL(file);
      setIsEdit(true);
      setDisabled && setDisabled(false);
      return;
    }
  };

  return (
    <span>
      <label
        htmlFor={name}
        style={{ display: "block", marginInlineStart: "unset" }}
      >
        {name.replaceAll("_", " ")}:
      </label>
      <img
        src={
          image.url ||
          `${value != "" ? LinkLikeBachend + value : background_img}`
        }
        alt={name}
      />
      <label htmlFor={name} onClick={() => setIsEdit(true)}>
        <FaRegEdit />
        {isEdit && (
          <input
            type="file"
            id={name}
            hidden
            name={name}
            onChange={(e) => handleImageChange(e)}
          />
        )}
      </label>
    </span>
  );
};
export default UpdateImgInput;
