import React from "react";
import "./headerPage.css";

const HeaderPage = ({ img, text_1, text_2 }) => {
  return (
    <header>
      <img src={img} alt="img" />

      <div className="text_header">
        <h1>{text_1}</h1>
        <p>{text_2}</p>
      </div>
    </header>
  );
};

export default HeaderPage;
