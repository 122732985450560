import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import form_contact_img_submit from "../../components/img/form_contact_img_submit.webp";
import "./contact.css";
import HeaderPage from "../../components/headerPage/HeaderPage";
import Button from "../../components/button/Button";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import axios from "axios";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [dataheaderContact, setDataheaderContact] = useState([]);
  const [dataContactMap, setDataContactMap] = useState([]);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataheaderContact = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/header_contact.php`,
        formDataPages
      );

      setDataheaderContact(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchDataContactMap = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/contact_map.php`,
        formDataPages
      );

      setDataContactMap(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchDataheaderContact();
    fetchDataContactMap();
  }, [fetchDataheaderContact, fetchDataContactMap]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData(e.target);
    FormDataSubmit.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}create/contact_us.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success(t("send_message"));
        setDisabledSubmit(false);

        e.target.reset();
      } else {
        toast.error(t("error_send_message"));
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error(t("error_send_message"));
    }
  };

  const text_1 = t("contact_us");
  const text_2 =
    language == "en"
      ? dataheaderContact.text_1_en
      : dataheaderContact.text_1_ar;

  return (
    <>
      <HeaderPage
        img={`${LinkLikeBachend}${dataheaderContact.img}`}
        text_1={text_1}
        text_2={text_2}
      />

      <form onSubmit={onSubmit} className="form_contact">
        <div className="firstName_lastName">
          <div>
            <label>{t("contact_text_3")}</label>
            <input type="text" required name="firstName" placeholder="Join" />
          </div>

          <div>
            <label>{t("contact_text_4")}</label>
            <input type="text" required name="lastName" placeholder="Doe" />
          </div>
        </div>

        <div className="firstName_lastName">
          <div>
            <label>{t("contact_text_5")}</label>
            <input
              type="email"
              required
              name="email"
              placeholder="example@gmail.com"
            />
          </div>

          <div>
            <label>{t("contact_text_6")}</label>
            <input
              type="number"
              required
              name="phoneNumber"
              placeholder="+00974 ×××× ××××"
            />
          </div>
        </div>

        <div className="select_subject">
          <label>{t("contact_text_7")}</label>
          <div className="select_subject_input">
            <div>
              <input
                type="radio"
                required
                name="subject"
                value={t("inquiry_1")}
                id="radio_1"
              />
              <span>
                <FaCheck />
              </span>
              <label htmlFor="radio_1">{t("inquiry_1")}</label>
            </div>
            <div>
              <input
                type="radio"
                name="subject"
                value={t("inquiry_2")}
                id="radio_2"
              />
              <span>
                <FaCheck />
              </span>
              <label htmlFor="radio_2">{t("inquiry_2")}</label>
            </div>
            <div>
              <input
                type="radio"
                name="subject"
                value={t("inquiry_3")}
                id="radio_3"
              />
              <span>
                <FaCheck />
              </span>
              <label htmlFor="radio_3">{t("inquiry_3")}</label>
            </div>
            <div>
              <input
                type="radio"
                name="subject"
                value={t("inquiry_4")}
                id="radio_4"
              />
              <span>
                <FaCheck />
              </span>
              <label htmlFor="radio_4">{t("inquiry_4")}</label>
            </div>
          </div>
        </div>

        <div className="message">
          <label>{t("contact_text_9")}</label>
          <input
            type="text"
            required
            name="message"
            placeholder={t("contact_text_10")}
          />
        </div>

        <div className="send_message">
          <div className="send_message_img">
            <img src={form_contact_img_submit} alt="form_contact_img_submit" />
          </div>
          <Button
            text={t("contact_text_11")}
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
          />
        </div>
      </form>

      <div className="map">
        <div className="info">
          <p>
            {t("contact_text_12")} <span>{dataContactMap.po_box}</span>
          </p>

          <div>
            <a href={`tel:+${dataContactMap.mobile}`} target="_blank">
              {t("mobile")} <span>+{dataContactMap.mobile}</span>
            </a>
            <a href={`tel:+${dataContactMap.landline}`} target="_blank">
              {t("contact_text_14")} <span>+{dataContactMap.landline}</span>
            </a>
          </div>

          <a href={`mailto:${dataContactMap.email}`} target="_blank">
            {t("email")} <span>{dataContactMap.email}</span>
          </a>
          <p>
            {t("contact_text_16")}
            <span>
              {language == "en"
                ? dataContactMap.address_en
                : dataContactMap.address_ar}
            </span>
          </p>
        </div>

        <div className="location">
          <iframe
            src={dataContactMap.address_google_map}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
