import axios from "axios";
import { useLoaderData } from "react-router-dom";
import styles from "./readStatic.module.css";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const loader = async ({ request, params }) => {
  const formDataPages = new FormData();
  formDataPages.append("validation", validation);

  try {
    const response = await axios.post(
      `${LinkLikeBachend}read/${params.page.replaceAll("-", "_")}.php`,
      formDataPages
    );

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const ReadStatic = () => {
  const data = useLoaderData();

  return (
    <section className={styles.user_msg}>
      {data.map((msg) => (
        <figure key={msg.id}>
          <h2>
            <b>Name:</b> {msg.first_last_name}
          </h2>
          <small>
            <b>Email:</b> {msg.email}
          </small>
          <small>
            <b>Phone:</b> {msg.phoneNumber}
          </small>
          <p>
            <b>Subject:</b> {msg.subject_message}
          </p>
          <p>
            <b>Message:</b> {msg.message_contact}
          </p>
          <small>{msg.date_add}</small>
        </figure>
      ))}
    </section>
  );
};
export default ReadStatic;
