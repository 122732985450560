import axios from "axios";
import { Link, Outlet, useLoaderData, useParams } from "react-router-dom";
import SingleEdit from "./SingleEdit";
import styles from "../dashboard.module.css";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const loader = async ({ request, params }) => {
  const formDataPages = new FormData();
  formDataPages.append("validation", validation);

  try {
    const response = await axios.post(
      `${LinkLikeBachend}read/${params.page.replaceAll("-", "_")}.php`,
      formDataPages
    );

    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const PostComponent = () => {
  const data = useLoaderData();

  return (
    <>
      <div className={styles.createContainer}>
        <Link to={`/dashboard/post/${useParams().page}/create`}>Create</Link>

        <Outlet context={{ sample: data[0] }} />
      </div>

      {data.map((post) => (
        <SingleEdit key={post.id} post={post} />
      ))}
    </>
  );
};
export default PostComponent;
