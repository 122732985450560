export const englishDataEn = {
  home: "HOME",
  about_us: "ABOUT US",
  services: "SERVICES",
  contact_us: "CONTACT US",
  inquiry: "inquiry",
  news: "news",
  mobile: "Mobile",
  email: "Email",
  vision: "our Vision",
  mission: "Mission",
  contact_text_3: "First Name",
  contact_text_4: "Last Name",
  contact_text_5: "Email",
  contact_text_6: "Phone Number",
  contact_text_7: "Select Subject?",
  contact_text_9: "Message",
  contact_text_10: "Write your message..",
  contact_text_11: "Send Message",
  contact_text_20: "subject",
  contact_text_21: "Send WhatsApp",
  contact_text_22: "Send mail",
  send_message: "Your message was sent successfully",
  error_send_message: "An error occurred when sending the message",
  button_about_page_4: "Discover our team",
  contact_text_12: "Po. Box",
  contact_text_14: "landline",
  contact_text_16: "Address",
  inquiry_1: "General inquiry",
  inquiry_2: "Horse insurance",
  inquiry_3: "Stables maintenance",
  inquiry_4: "customs clearance",
  site_under_maintenance: "The site is under maintenance",
};

export default englishDataEn;
