import React, { useCallback, useEffect, useState } from "react";
import { Slider } from "./Slider";
import { useTranslation } from "react-i18next";
import axios from "axios";
import logo_about from "../../components/img/logo_about.webp";
import Services from "../services/Services";
import Inquiry from "../inquiry/Inquiry";

const Home = () => {
  const { i18n } = useTranslation();
  const [dataTextImgSlider, setDataTextImgSlider] = useState([]);
  const [dataText2Home, setDataText2Home] = useState([]);
  const [dataDivImgInfo, setDataDivImgInfo] = useState([]);

  const language = i18n.language;

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataTextImgSlider = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/text_img_slider.php`,
        formDataPages
      );

      setDataTextImgSlider(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  const fetchDataText2Home = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/text_2_home.php`,
        formDataPages
      );

      setDataText2Home(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  const fetchDatadivImgInfo = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/div_img_info.php`,
        formDataPages
      );

      setDataDivImgInfo(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  useEffect(() => {
    fetchDataTextImgSlider();
    fetchDatadivImgInfo();
    fetchDataText2Home();
  }, [fetchDataTextImgSlider, fetchDatadivImgInfo, fetchDataText2Home]);

  // console.log(dataTextImgSlider);
  // console.log(dataText2Home);
  console.log(dataDivImgInfo);

  const div_index = [
    {
      img: `${LinkLikeBachend}${dataDivImgInfo.image_url_1}`,
      p_1_en: dataDivImgInfo.text_1_en,
      p_1_ar: dataDivImgInfo.text_1_ar,
      p_2_en: dataDivImgInfo.text_1_info_en,
      p_2_ar: dataDivImgInfo.text_1_info_ar,
    },
    {
      img: `${LinkLikeBachend}${dataDivImgInfo.image_url_2}`,
      p_1_en: dataDivImgInfo.text_2_en,
      p_1_ar: dataDivImgInfo.text_2_ar,
      p_2_en: dataDivImgInfo.text_2_info_en,
      p_2_ar: dataDivImgInfo.text_2_info_ar,
    },
    {
      img: `${LinkLikeBachend}${dataDivImgInfo.image_url_3}`,
      p_1_en: dataDivImgInfo.text_3_en,
      p_1_ar: dataDivImgInfo.text_3_ar,
      p_2_en: dataDivImgInfo.text_3_info_en,
      p_2_ar: dataDivImgInfo.text_3_info_ar,
    },
    {
      img: `${LinkLikeBachend}${dataDivImgInfo.image_url_4}`,
      p_1_en: dataDivImgInfo.text_4_en,
      p_1_ar: dataDivImgInfo.text_4_ar,
      p_2_en: dataDivImgInfo.text_4_info_en,
      p_2_ar: dataDivImgInfo.text_4_info_ar,
    },
  ];

  return (
    <>
      <header>
        <Slider />

        <div className="text_header">
          {language == "en" ? (
            <>
              <h4>{dataTextImgSlider.text_1_en}</h4>
              <div className="text">
                <h4 className="p_1">{dataTextImgSlider.text_2_en}</h4>
                <h4 className="p_2">{dataTextImgSlider.text_3_en}</h4>
              </div>
            </>
          ) : (
            <>
              <h4>{dataTextImgSlider.text_1_ar}</h4>
              <div className="text">
                <h4 className="p_1">{dataTextImgSlider.text_2_ar}</h4>
                <h4 className="p_2">{dataTextImgSlider.text_3_ar}</h4>
              </div>
            </>
          )}
        </div>
      </header>

      <div className="main">
        {language == "en" ? (
          <>
            <div>
              <h1>{dataText2Home.text_1_en}</h1>
              <p>{dataText2Home.text_2_en}</p>
            </div>
            <p className="text_width">{dataText2Home.text_3_en}</p>
          </>
        ) : (
          <>
            <div>
              <h1>{dataText2Home.text_1_ar}</h1>
              <p>{dataText2Home.text_2_ar}</p>
            </div>
            <p className="text_width">{dataText2Home.text_3_ar}</p>
          </>
        )}
      </div>

      <div className="img_info_home">
        <div className="div_img_info">
          {div_index?.map((item, i) => (
            <div className="div_index" key={i}>
              <img src={item.img} alt="img_info_home" />
              <div className="item_img">
                {language == "en" ? (
                  <>
                    <p className="p_1">{item.p_1_en}</p>
                    <p className="p_2">{item.p_2_en}</p>
                  </>
                ) : (
                  <>
                    <p className="p_1">{item.p_1_ar}</p>
                    <p className="p_2">{item.p_2_ar}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Services />

      <Inquiry />

      <div className="logo_about">
        <img src={logo_about} alt="logo_about" />
      </div>
    </>
  );
};

export default Home;
