import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

const SocialMedia = () => {
  const [dataSocialMedia, setDataSocialMedia] = useState([]);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataSocialMedia = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/social_media.php`,
        formDataPages
      );

      setDataSocialMedia(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchDataSocialMedia();
  }, [fetchDataSocialMedia]);

  // console.log(dataSocialMedia);

  return (
    <>
      {dataSocialMedia.map((item, i) => (
        <a href={item.like_social_media} target="_blank" key={i}>
          <img
            src={`${LinkLikeBachend}${item.img_social_media}`}
            alt="img_social_media"
          />
        </a>
      ))}
    </>
  );
};

export default SocialMedia;
