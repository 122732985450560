import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "./dashboard.module.css";
import Cookies from "js-cookie";
import { FaUser } from "react-icons/fa6";
import { HiHome } from "react-icons/hi";

const links = [
  {
    name: "About Info",
    path: "/dashboard/update/about-info",
  },
  {
    name: "Contact Map",
    path: "/dashboard/update/contact-map",
  },
  {
    name: "Div Img Info",
    path: "/dashboard/update/div-img-info",
  },
  {
    name: "gallery info",
    path: "/dashboard/update/gallery-info",
  },
  {
    name: "about header",
    path: "/dashboard/update/header-about",
  },
  {
    name: "contact header",
    path: "/dashboard/update/header-contact",
  },
  {
    name: "services header",
    path: "/dashboard/update/header-services",
  },
  {
    name: "navbar",
    path: "/dashboard/update/navbar",
  },
  {
    name: "home text",
    path: "/dashboard/update/text-2-home",
  },
  {
    name: "image slider text",
    path: "/dashboard/update/text-img-slider",
  },
  {
    name: "vision mission",
    path: "/dashboard/update/vision-mission",
  },
  {
    name: "why us info",
    path: "/dashboard/update/why-us-info",
  },
  {
    name: "Gallery Post",
    path: "/dashboard/post/gallery-post",
  },
  {
    name: "image slider",
    path: "/dashboard/post/img-slider",
  },
  {
    name: "services post",
    path: "/dashboard/post/services-post",
  },
  {
    name: "social media",
    path: "/dashboard/post/social-media",
  },
  {
    name: "why us post",
    path: "/dashboard/post/why-us-post",
  },
  {
    name: "users' messages",
    path: "/dashboard/read/contact-us",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/dashboard">Admin Panel</Link>
        <div>
          <span>
            <FaUser />
            <p>Hello Admin</p>
          </span>
          <span className="logout">
            <button>
              <Link to={"/change-password"}>Change Password</Link>
            </button>
            <button
              onClick={() => {
                Cookies.remove("admin_token");
                navigate("/admin");
              }}
            >
              Logout
            </button>
          </span>
        </div>
      </nav>

      <main>
        <h1 className={styles.page_header}>Dashboard</h1>
        <div className={styles.breedcrump}>
          <HiHome />
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : "")}
            to="/"
          >
            Home
          </NavLink>
          <span>/</span>
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : "")}
            to="/dashboard"
          >
            Panel
          </NavLink>
        </div>

        <div className={styles.content}>
          <div className={styles.links} style={{ display: "flex" }}>
            {links.map((link) => (
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : "")}
                key={link.name}
                to={link.path}
                reloadDocument={true}
              >
                {link.name}
              </NavLink>
            ))}
          </div>
          <Outlet />
        </div>
      </main>
    </>
  );
};
export default Dashboard;
