import styles from "./admin.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useEffect } from "react";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const AdminPage = () => {
  const navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    const formDataPages = new FormData(e.target);
    formDataPages.append("validation", validation);

    try {
      const res = await axios.post(
        `${LinkLikeBachend}read/login_admin.php`,
        formDataPages
      );

      console.log(res.data);

      if (res.data.request === "password_incorrect")
        return toast.error("Password incorrect");

      toast.success("Logged in successfully");
      Cookies.set("admin_token", res.data.newtoken, {
        expires: 1,
        secure: true,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error:", error);
    }
  };


  useEffect(() => {
    if (window.location.pathname === "/dashboard")
      Cookies.get("admin_token") && window.location.reload();
  }, [Cookies.get("admin_token")]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <h2>Login</h2>
          <form onSubmit={onSubmit}>
            <input
              type="password"
              id="password"
              placeholder="Password"
              required
              name="password"
            />

            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default AdminPage;
