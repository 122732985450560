import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAppContext } from "./components/context/AppProvider";
import Navbar from "./components/navbar/Navbar";
import Home from "./page/home/Home";
import NotFound from "./page/notFound/NotFound";
import About from "./page/about/About";
import Contact from "./page/contact/Contact";
import AdminPage from "./page/admin/AdminPage";
import Dashboard from "./page/dashboard/Dashboard";
import Cookies from "js-cookie";
import UpdateStatics, {
  loader as UpdateLoader,
  action as UpdateAction,
} from "./page/dashboard/updateStatics/updateStatics";
import PostComponent, {
  loader as PostLoader,
} from "./page/dashboard/postComponent/postComponent";
import { action as editCrudAction } from "./page/dashboard/postComponent/SingleEdit";
import Create, {
  action as createAction,
} from "./page/dashboard/postComponent/Create";
import ReadStatic, {
  loader as ReadLoader,
} from "./page/dashboard/readStatic/readStatic";
import ChangePassword from "./page/changePassword/ChangePassword";

const App = () => {
  const { direction, setDirection } = useAppContext();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navbar setDirection={setDirection} />,
      errorElement: <NotFound />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
    {
      path: "/admin",
      element: <AdminPage />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/dashboard/",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
      errorElement: <NotFound />,
      children: [
        {
          path: "/dashboard/update/:page",
          element: <UpdateStatics />,
          loader: UpdateLoader,
          action: UpdateAction,
        },
        {
          path: "/dashboard/read/:page",
          element: <ReadStatic />,
          loader: ReadLoader,
        },
        {
          path: "/dashboard/post/:page",
          element: <PostComponent />,
          loader: PostLoader,
          action: editCrudAction,

          children: [
            {
              path: "/dashboard/post/:page/create",
              element: <Create />,
              action: createAction,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div
      className={`direction direction_${direction}`}
      style={{ direction: direction }}
    >
      <RouterProvider router={router} />
    </div>
  );
};

export default App;

function ProtectedRoute({ children }) {
  return Cookies.get("admin_token") ? children : <AdminPage />;
}
