import React, { useCallback, useEffect, useRef, useState } from "react";
import "./navbar.css";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenu } from "react-icons/hi";
import IoIosPhonePortrait from "../img/IoIosPhonePortrait.webp";
import MdOutlineEmail from "../img/MdOutlineEmail.webp";
import axios from "axios";
import SocialMedia from "../socialMedia/SocialMedia";
import Copyrights from "../copyrights/Copyrights";
import { Link } from "react-scroll";

const Navbar = ({ setDirection }) => {
  const [scrolling, setScrolling] = useState(false);
  const [isHash, setHash] = useState(false);
  const [dataNav, setDataNav] = useState([]);
  const location = useLocation();
  const myElementRef = useRef(null);
  const { i18n, t } = useTranslation();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataNav = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/navbar.php`,
        formDataPages
      );

      setDataNav(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [dataNav]);

  useEffect(() => {
    fetchDataNav();

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    setTimeout(() => {
      if (location.hash) {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        const height = 250;

        if (targetElement) {
          const offset = height;
          const elementTop =
            targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetTop = elementTop - offset;

          window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
      }
    }, 500);

    if (location.pathname != "/") {
      setHash(true);
    } else if (location.hash == "#services") {
      setHash(false);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname, location.hash]);

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  const handleOpenNav = () => {
    document.getElementById("myNav").style.height = "100vh";
  };
  const handleCloseNav = () => {
    document.getElementById("myNav").style.height = "0";
  };

  const link_Route = [
    { text: t("home"), to: "/" },
    { text: t("about_us"), to: "/about" },
    { text: t("services"), to: "services" },
    { text: t("mission"), to: "services" },
    { text: t("contact_us"), to: "/contact" },
  ];

  return (
    <>
      <h2>{t("site_under_maintenance")}</h2>

      <nav className={scrolling ? "scrolled" : ""} ref={myElementRef}>
        <div className="left">
          <NavLink to="/" className="logo">
            <img
              src={
                scrolling
                  ? `${LinkLikeBachend}${dataNav.logo_lite}`
                  : `${LinkLikeBachend}${dataNav.logo}`
              }
              alt="img_logo"
            />
          </NavLink>

          <div className="email_phone computer color">
            <a href={`tel:+${dataNav.phone}`} target="_blank">
              <img src={IoIosPhonePortrait} alt="IoIosPhonePortrait" />
              {dataNav.phone}
            </a>
            <a href={`mailto:${dataNav.email}`} target="_blank">
              <img src={MdOutlineEmail} alt="IoIosPhonePortrait" />
              {dataNav.email}
            </a>
          </div>

          <div className="social_media computer color">
            <SocialMedia />
          </div>
        </div>

        <div className="right color">
          <div className="lang">
            <button className="ChangeLang" onClick={ChangeAR}>
              Ar
            </button>
            <button className="ChangeLang" onClick={ChangeEN}>
              ENG
            </button>
          </div>
          <div className="lang">
            <Link
              to="inquiry"
              spy={true}
              smooth={true}
              offset={-250}
              duration={1000}
              className="inquiry_news"
            >
              {t("inquiry")}
            </Link>

            <button className="computer inquiry_news">{t("news")}</button>

            <HiOutlineMenu onClick={handleOpenNav} />
          </div>
        </div>
      </nav>

      <div id="myNav" className="overlay" onClick={handleCloseNav}>
        <IoClose className="closebtn" onClick={handleCloseNav} />

        <div className="overlay-content">
          {link_Route.map((item, i) =>
            item.to == "services" ? (
              isHash ? (
                <NavLink key={i} to="/#services">
                  {item.text}
                </NavLink>
              ) : (
                <Link
                  key={i}
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-250}
                  duration={1000}
                  onClick={handleCloseNav}
                >
                  {item.text}
                </Link>
              )
            ) : (
              <NavLink key={i} to={item.to}>
                {item.text}
              </NavLink>
            )
          )}

          <div className="email_phone phone">
            <a href={`tel:+${dataNav.phone}`} target="_blank">
              <img src={IoIosPhonePortrait} alt="IoIosPhonePortrait" />
              {dataNav.phone}
            </a>
            <a href={`mailto:${dataNav.email}`} target="_blank">
              <img src={MdOutlineEmail} alt="IoIosPhonePortrait" />
              {dataNav.email}
            </a>
          </div>

          <div className="social_media phone">
            <SocialMedia />
          </div>

          <div className="right phone">
            <div className="lang">
              <button>{t("news")}</button>
            </div>
          </div>
        </div>
      </div>

      <Outlet />

      <Footer />

      <Copyrights />
    </>
  );
};

export default Navbar;
