import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";
import SocialMedia from "../socialMedia/SocialMedia";
import axios from "axios";
import { Link } from "react-scroll";

const Footer = () => {
  const { t } = useTranslation();
  const [dataNav, setDataNav] = useState([]);
  const [isHash, setHash] = useState(false);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataNav = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/navbar.php`,
        formDataPages
      );

      setDataNav(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [dataNav]);

  const location = useLocation();

  useEffect(() => {
    fetchDataNav();
    window.scrollTo(0, 0);

    if (location.pathname != "/") {
      setHash(true);
    } else if (location.hash == "#services") {
      setHash(false);
    }
  }, [location.hash, location.pathname]);

  const link_Route = [
    { text: t("home"), to: "/" },
    { text: t("about_us"), to: "/about" },
    { text: t("services"), to: "services" },
    { text: t("mission"), to: "services" },
    { text: t("contact_us"), to: "/contact" },
  ];

  return (
    <>
      <footer>
        <div className="left">
          <div className="leftNavLink">
            {link_Route.slice(0, 2).map((item, i) => (
              <NavLink key={i} to={item.to}>
                {item.text}
              </NavLink>
            ))}
          </div>

          <div className="leftNavLink">
            {link_Route.slice(2).map((item, i) =>
              item.to == "services" ? (
                isHash ? (
                  <NavLink key={i} to="/#services">
                    {item.text}
                  </NavLink>
                ) : (
                  <Link
                    key={i}
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-250}
                    duration={1000}
                  >
                    {item.text}
                  </Link>
                )
              ) : (
                <NavLink key={i} to={item.to}>
                  {item.text}
                </NavLink>
              )
            )}
          </div>
        </div>
        <div className="center">
          <NavLink to="/" className="logo_footer">
            <img
              src={`${LinkLikeBachend}${dataNav.logo_footer}`}
              alt="logo_footer"
            />
          </NavLink>
        </div>

        <div className="right">
          <div className="email_phone">
            <a href={`tel:${dataNav.phone}`} target="_blank">
              {t("mobile")}: <br /> {dataNav.phone}
            </a>

            <a href={`mailto:${dataNav.email}`} target="_blank">
              {t("email")}: <br /> {dataNav.email}
            </a>
          </div>

          <div className="social_media">
            <SocialMedia />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
