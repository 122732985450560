import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./services.css";
import Button from "../../components/button/Button";
import axios from "axios";

const Services = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [dataheaderServices, setDataheaderServices] = useState([]);
  const [dataServicesPost, setDataServicesPost] = useState([]);
  const [dataVisionMission, setDataVisionMission] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataheaderServices = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/header_services.php`,
        formDataPages
      );

      setDataheaderServices(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  const fetchDataServicesPost = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/services_post.php`,
        formDataPages
      );

      setDataServicesPost(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  const fetchDataVisionMission = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/vision_mission.php`,
        formDataPages
      );

      setDataVisionMission(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  useEffect(() => {
    fetchDataheaderServices();
    fetchDataServicesPost();
    fetchDataVisionMission();
  }, [fetchDataheaderServices, fetchDataServicesPost, fetchDataVisionMission]);

  // console.log(dataheaderServices);
  // console.log(dataServicesPost);
  // console.log(dataVisionMission);

  return (
    <div className="services" id="services">
      <div className="services_info">
        {dataServicesPost.map((item, i) => (
          <div className="services_info_item" key={i}>
            {/* <div>
              <img src={`${LinkLikeBachend}${item.img}`} alt={item.text_1_en} />
            </div> */}
            <p>{language == "en" ? item.text_1_en : item.text_1_ar}</p>
          </div>
        ))}
      </div>

      {/* <div className="main">
        <Button text={t("button_about_page_4")} />
      </div> */}

      <div className="main">
        <div>
          <h1>{t("vision")}</h1>
        </div>
        <p className="text_width">
          {language == "en"
            ? dataVisionMission.text_vision_en
            : dataVisionMission.text_vision_ar}
        </p>
      </div>

      <div className="img_about_home_3">
        <img
          src={`${LinkLikeBachend}${dataVisionMission.img_vision}`}
          alt="img_vision"
        />
      </div>

      <div className="main" id="mission">
        <div>
          <h1>{t("mission")}</h1>
        </div>
        <p className="text_width">
          {language == "en"
            ? dataVisionMission.text_mission_en
            : dataVisionMission.text_mission_ar}
        </p>
      </div>

      <div className="img_about_home_3">
        <img
          src={`${LinkLikeBachend}${dataVisionMission.img_mission}`}
          alt="img_mission"
        />
      </div>
    </div>
  );
};

export default Services;
