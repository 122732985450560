import { Form, useParams, useRevalidator } from "react-router-dom";
import UpdateTextInput from "../updateTextInput";
import UpdateImgInput from "../updateImgInput";
import styles from "../dashboard.module.css";
import axios from "axios";
import { toast } from "react-toastify";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  formData.append("validation", validation);
  const updates = Object.fromEntries(formData);

  console.log("Create update");
  console.log(params.page);

  Object.entries(updates).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const response = await axios.post(
      `${LinkLikeBachend}update/${params.page}.php`,
      formData
    );

    if (response.data.request == "successfully") {
      toast.success("Updated successfully");
    } else if (response.data.request == "img_empty") {
      toast.warning("The image is blank");
    } else {
      toast.error("An error occurred when updating");
      console.log(response.data);
    }
  } catch (error) {
    console.error("Error:", error);
  }

  return updates;
};

const SingleEdit = ({ post }) => {
  const params = useParams();
  const revalidator = useRevalidator();
  let textKeys = [],
    imgKeys = [];

  textKeys = Object.keys(post).filter(
    (key) =>
      !key.includes("image") &&
      !key.includes("img") &&
      !key.includes("logo") &&
      !key.includes("id")
  );
  imgKeys = Object.keys(post).filter(
    (key) =>
      (key.includes("image") || key.includes("img") || key.includes("logo")) &&
      !key.includes("id")
  );

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append("validation", validation);
    formData.append("id", post.id);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}delete/${params.page}.php`,
        formData
      );

      if (response.data.request == "successfully") {
        toast.success("Deleted successfully");
        revalidator.revalidate();
      } else {
        toast.error("An error occurred when Deleted");
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  if (textKeys.length !== 0 || imgKeys.length !== 0)
    return (
      <Form method="post" encType="multipart/form-data">
        <input type="hidden" name="id" value={post.id} readOnly />

        {imgKeys?.map((img) => (
          <UpdateImgInput
            key={post.id}
            value={post[img]}
            name={`${img}_${post.id}`}
          />
        ))}

        {textKeys?.map((key) => (
          <UpdateTextInput key={key} value={post[key]} name={key} />
        ))}

        <div className={styles.btns}>
          <button type="submit">Update</button>
          <button type="reset" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </Form>
    );
};
export default SingleEdit;
