import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./inquiry.css";
import Button from "../../components/button/Button";
import { toast } from "react-toastify";
import axios from "axios";

const Inquiry = () => {
  const { t } = useTranslation();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const onSubmit = async (e) => {
    e.preventDefault();

    const buttonClicked = e.nativeEvent.submitter;
    const sendData = buttonClicked.getAttribute("data-send");

    const FormDataSubmit = new FormData(e.target);
    const formValues = {};
    FormDataSubmit.forEach((value, key) => {
      formValues[key] = value;
    });

    setDisabledSubmit(true);

    if (sendData == "whatsApp") {
      const formattedText = Object.entries(formValues)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");

      window.open(
        `https://wa.me/97470100403/?text=HI Syndicate Market %0A %0A Inquiry %0A ${encodeURIComponent(
          formattedText
        )}`,
        "_blank",
        "noreferrer"
      );

      e.target.reset();
    } else {
      FormDataSubmit.append("validation", validation);

      try {
        const response = await axios.post(
          `${LinkLikeBachend}read/send_message.php`,
          FormDataSubmit
        );

        setDisabledSubmit(false);

        if (response.data == "successfully") {
          toast.success(t("send_message"));
          setDisabledSubmit(false);

          e.target.reset();
        } else {
          toast.error(t("error_send_message"));
          console.log(response.data);
          setDisabledSubmit(false);
        }
      } catch (error) {
        setDisabledSubmit(false);
        console.error("Error:", error);
        toast.error(t("error_send_message"));
      }
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className="form_contact inquiry" id="inquiry">
        <div className="firstName_lastName">
          <div>
            <label>{t("contact_text_5")}</label>
            <input
              type="email"
              required
              name="email"
              placeholder="example@gmail.com"
            />
          </div>

          <div>
            <label>{t("contact_text_6")}</label>
            <input
              type="number"
              required
              name="phoneNumber"
              placeholder="+00974 ×××× ××××"
            />
          </div>
        </div>

        <div className="firstName_lastName">
          <div>
            <label>{t("contact_text_20")}</label>
            <input
              type="text"
              required
              name="subject"
              placeholder={t("contact_text_20")}
            />
          </div>
        </div>

        <div className="message">
          <label>{t("contact_text_9")}</label>
          <input
            type="text"
            required
            name="message"
            placeholder={t("contact_text_10")}
          />
        </div>

        <div className="send_message">
          <Button
            text={t("contact_text_21")}
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
            data_send="whatsApp"
          />

          <Button
            text={t("contact_text_22")}
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
            data_send="mail"
          />
        </div>
      </form>
    </>
  );
};

export default Inquiry;
