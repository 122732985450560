import {
  Form,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UpdateImgInput from "../updateImgInput";
import UpdateTextInput from "../updateTextInput";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import background_img from "../../../components/img/background_img.webp";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  formData.append("validation", validation);

  const updates = Object.fromEntries(formData);

  console.log("Create");
  console.log(params.page);

  Object.entries(updates).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const response = await axios.post(
      `${LinkLikeBachend}create/${params.page}.php`,
      formData
    );

    toast.success("Added successfully");
    // reset values
    document
      .querySelectorAll("#create input")
      .forEach((inp) => (inp.value = ""));
    document
      .querySelectorAll("#create img")
      .forEach((img) => (img.src = background_img));
  } catch (error) {
    toast.error("An error occurred when Added, Add an image please");
    console.error("Error:", error);
  }

  return updates;
};

const Create = () => {
  const { sample } = useOutletContext();
  const [disabled, setDisabled] = useState(true);
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let textKeys = [],
    imgKeys = [];

  if (sample) {
    textKeys = Object.keys(sample).filter(
      (key) =>
        !key.includes("image") &&
        !key.includes("img") &&
        !key.includes("logo") &&
        !key.includes("id")
    );
    imgKeys = Object.keys(sample).filter(
      (key) =>
        (key.includes("image") ||
          key.includes("img") ||
          key.includes("logo")) &&
        !key.includes("id")
    );
  }

  if (textKeys.length === 0 && imgKeys.length === 0) {
    if (searchParams.get("img") === "1") {
      if (params.page === "social-media") imgKeys.push("img_social_media");
      else imgKeys.push("img");
    }

    if (searchParams.get("text") === "1") {
      if (params.page === "social-media") textKeys.push("like_social_media");
    }

    if (searchParams.get("text") === "2") {
      textKeys.push("text_1_en");
      textKeys.push("text_1_ar");
    }
  }

  useEffect(() => {
    if (params.page === "gallery-post" || params.page === "img-slider")
      setSearchParams({ img: "1" });

    if (params.page === "social-media")
      setSearchParams({ img: "1", text: "1" });

    if (params.page === "services-post" || params.page === "why-us-post")
      setSearchParams({ img: "1", text: "2" });
  }, []);

  return (
    <Form method="post" encType="multipart/form-data" id="create">
      {imgKeys?.map((key) => (
        <UpdateImgInput
          key={key}
          value=""
          name={`${key}_create`}
          setDisabled={setDisabled}
        />
      ))}

      {textKeys?.map((key) => (
        <UpdateTextInput key={key} value="" name={`${key}_create`} />
      ))}

      <button type="submit" disabled={disabled}>
        Post
      </button>
    </Form>
  );
};
export default Create;
